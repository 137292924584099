import React, {useEffect, useState} from 'react';
import logo from './logo.svg';
import './App.css';

function App() {

  const [debug, setDebug] = useState('debug webview');

  const sendMessage = () => {
    if(window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({type: 'action', 'payload': 'Scan QR'}));
      setDebug("Message sent");
    }
  };

  function handleMessage(event) {
    console.log(event.data);
    setDebug(JSON.stringify(event.data));
  }

  useEffect(() => {
    document.addEventListener('message', handleMessage);
    return () => {
      document.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
      <div className="App">
        <header className="App-header">


          <div style={{ padding:'15px', backgroundColor: 'blue'}}>
            DEBUG WEBVIEW ICI
            {debug && <p>{debug}</p>}
          </div>

          <img src={logo} className="App-logo" alt="logo"/>

          <button onClick={sendMessage}>SCAN QR CODE</button>
        </header>
      </div>
  );
}

export default App;